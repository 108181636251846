import "./servicesText.css";

export default function ServicesText() {
    return (
        <div className="headerContainer">
            <h1 className="servicesNameHeader">De Novo Beauty</h1>
            <h2 className="headerLarge">Popular Services</h2>
        </div>
    );
}
